/**
 * Helper to
 * - retrieve sizes from CSS custom properties
 * - parse and process sizes
 * - return results to HTML attributes
 */
export default Object.freeze({
    /**
     * Get size data from CSS custom properties.
     * @param {HTMLElement} target
     * @returns {width: number, height: number} Object with size data
     */
    getSizeData(target) {
        const styles = window.getComputedStyle(target);
        return {
            width: styles.getPropertyValue('--container-widths'),
            height: styles.getPropertyValue('--container-heights'),
        };
    },

    /**
     * Checks if element has size data required for observing.
     * @param {HTMLElement} target
     * @returns {boolean} Has required size data.
     */
    hasSizeData(target) {
        const { width, height } = this.getSizeData(target);

        return width || height;
    },

    /**
     * Sets the appropriate breakpoint information via HTMLAttributes for the targets.
     * @param {ResizeObserverEntry} entry
     */
    setBreakpoints(entry) {
        const { contentRect, target } = entry;
        const sizeData = this.getSizeData(target);

        /**
         * Processing the sizes object with the following logic:
         * 1. Convert the size object into an iterable.
         * 2. Replace any double-quotes from the attribute value.
         * 3. Split the value into an array.
         * 4. Convert each entry into a float.
         * 5. Filter out values that do not match the requirement.
         * 6. Join back the values.
         * 7. Filter out sizes (either `width` and/or `height`) with nullish values.
         * 8. Re-apply those values via the target’s `data-container-*` attribute.
         */
        Object.entries(sizeData) // [1]
            .map(([size, value]) => [
                size, value
                    .replace('"', '') // [2]
                    .split(' ') // [3]
                    .map(parseFloat) // [4]
                    .filter((bpValue) => contentRect[size] >= bpValue) // [5]
                    .join(' '), // [6]
            ])
            .filter(([, value]) => !!value) // [7]
            // Add minimum matching breakpoints as data attribute, space separated
            .forEach(([size, value]) => target.setAttribute(`data-container-${size}`, value)); // [8]

        target.setAttribute('data-container-initialized', true);
    },
});
